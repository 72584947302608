import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import InlineImageGallery from "../components/InlineImageGallery.jsx";
import QuoteBlock from "../components/_ui/QuoteBlock.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Plant Information`}</h1>
    <p>{`A pergola is designed to provide shade and filtered sunlight and is an ideal structure on which to grow climbing plants as the beams and cross rafters provide a natural support. Originally used to cover walkways in Renaissance times, the pergolas would become covered with vines, creating organic tunnels through which people could wander protected from sun and rain.`}</p>
    <QuoteBlock quote="It is clear that Perfect Pergolas are passionate about their product and use only the best quality wood from sustainable sources. I am excited by the prospect of inspiring more people, both young and old, to get outside and make the most of what our wonderful gardens have to offer. Your first step should be to purchase a Perfect Pergola giving you a versatile, usable and beautiful space from which to explore all that nature has to offer on our doorstep.<br/><br/>I have recommended some wonderful climbing plants, suitable for most UK climates and soils that will complement your pergola perfectly. Plus, a few tips on planting, training and caring for them to obtain the best possible results. Not only will they look great but the scents and aromas are fabulous and if you choose to plant some vines - you can eat the fruit as well!" authorName="Karen Scott" authorPosition="Thistle Design" authorAvatar="/img/karen-avatar.jpg" mdxType="QuoteBlock" />
    <p>{`There are many different varieties of climbers from evergreen to deciduous, some with magnificent flowers and scents and some more suited to specialist conditions - the list is endless. However, to get you started we have come up with a simple selection of climbing plants and vines that are suitable for most UK areas and will not only look great but bring an abundance of colour and enticing aromas to your Perfect Pergola.`}</p>
    <p><strong parentName="p">{`Our recommended climbers can be divided into four sections:`}</strong></p>
    <ol>
      <li parentName="ol">{`Clematis`}</li>
      <li parentName="ol">{`Honeysuckle`}</li>
      <li parentName="ol">{`Grape vines`}</li>
      <li parentName="ol">{`Evergreen/Scented`}</li>
    </ol>
    <h2>{`Clematis`}</h2>
    <p>{`Clematis is one of the most popular garden plants as it is so versatile. The climbing varieties can be grown up walls, pergolas or left to meander through trees.`}</p>
    <p>{`Clematis need moisture-retentive but well drained soil and most climbers will thrive in full sun or partial shade. Keep the base of the plant and its roots cool and shaded by either carefully positioning other plants or place a layer of pebbles or flat stones around the base of the stem. Planting time is best during Spring or early Autumn with flowering times varying between Winter to late Summer. Aspect: full sun or partial shade.`}</p>
    <InlineImageGallery images={[{
      src: "/img/plants/clematis-1.jpg",
      alt: "Clematis"
    }, {
      src: "/img/plants/Clematis_Orientalis.jpg",
      alt: "Clematis Orentialis"
    }, {
      src: "/img/plants/Clematis_Perle_dAzure.jpg",
      alt: "Clematis Perle dAzure"
    }, {
      src: "/img/plants/Clematis_Tangutica.jpg",
      alt: "Clematis Tangutica"
    }, {
      src: "/img/plants/Clematis_White_Columbine.jpg",
      alt: "Clematis White Columbine"
    }, {
      src: "/img/plants/Clematis_Bill_Mackensie.jpg",
      alt: "Clematis Bill Mackensie"
    }]} mdxType="InlineImageGallery" />
    <h4>{`Ground Planting`}</h4>
    <p>{`Plant with the tops of their root balls 2 – 3 ins below the soil surface as this will encourage shoots to grow from below soil level.`}</p>
    <h4>{`Maintenance`}</h4>
    <p>{`Either in late Winter or early Spring apply a potassium rich fertilliser (ie: rose fertiliser) according to the manufacturer’s instructions. Mulch immediately afterwards with organic matter such as well rotted manure or garden compost. Water regularly during periods of dry weather for the first few seasons after planting with the equivalent of at least 4 watering cans per square metre.`}</p>
    <h4>{`Container Planting`}</h4>
    <p>{`Use containers that are at least 18 inches deep and wide and a soil-based potting compost`}</p>
    <h4>{`Maintenance`}</h4>
    <p>{`Make sure you water regularly throughout the growing season and apply a general purpose liquid fertiliser monthly during Spring and Summer. Refresh the top 1 – 2 inches of the compost each Spring with new potting compost.`}</p>
    <h4>{`Pruning`}</h4>
    <p>{`Initially, to avoid the development of a straggly single stem and to encourage branching lower down, cut back all newly planted clematis to 6 – 12 inches from ground level in February or March cutting just above a bud. Even if planting later in the Spring, hard pruning is still advisable. Delay pruning of autumn planted clematis until the following Spring. Pinch out developing young shoots once or twice to promote further branching.`}</p>
    <p>{`Once the clematis has become established the pruning times vary according to when your clematis variety flowers and can be divided into 3 separate groups:
Pruning group 1
Pruning group 2
Pruning group 3`}</p>
    <p>{`For further details, please visit `}<a parentName="p" {...{
        "href": "http://www.rhs.org.uk"
      }}>{`www.rhs.org.uk`}</a>{` and search for the clematis pruning information with respect to the above groups.`}</p>
    <h4>{`Suggested Plants`}</h4>
    <p><strong parentName="p">{`Ground planting in sunnier (ie west/ south west or south facing) aspects:`}</strong>{`
Clematis Orientalis
Clematis Tangutica`}</p>
    <p><strong parentName="p">{`Container Plants:`}</strong>{`
Clematis ‘Barbara Jackman’
Clematis ‘White Columbine’`}</p>
    <h2>{`Honeysuckle`}</h2>
    <p>{`Honeysuckles are popular climbing garden plants on account of their wonderful flowers and scent. They look beautiful draped over pergolas and are easy to grow. They are mostly fully hardy and flower during the summer months. They thrive in sunny or partial shade and can be either deciduous or evergreen.`}</p>
    <InlineImageGallery images={[{
      src: "/img/plants/Lonicera_Japonica_Halliana.jpg",
      alt: "Lonicera Japonica Halliana"
    }, {
      src: "/img/plants/Lonicera_Periclymenum.jpg",
      alt: "Lonicera Periclymenum"
    }]} mdxType="InlineImageGallery" />
    <h4>{`Planting`}</h4>
    <p>{`Climbing honeysuckles prefer fertile, humus rich soil, moist but well drained either direct into the ground or a suitably sized container. They will flower best with the top growth in full sun. However, they are less prone to aphid attack when placed in partial shade. Honeysuckles like a mulch around the base of organic matter, such as a well-rotted manure or garden compost to reduce water stress. A top dressing in Spring with a general fertiliser is recommended and will help promote growth and flowering.`}</p>
    <h4>{`Pruning`}</h4>
    <p>{`There are two methods depending on variety:`}</p>
    <ol>
      <li parentName="ol">{`Honeysuckles flowering on the current season’s growth (ie: Lonicera japonica) do not require regular pruning. Simply control the growth by cutting back any overlong shoots in Spring. Thin out congested growth and remove weak or damaged stems.`}</li>
      <li parentName="ol">{`Honeysuckles that flower early in the season or on short side shoots on the previous year’s growth (ie: Lonicera periclymemum) should be pruned back by about one – third in late Summer immediately after flowering.`}</li>
    </ol>
    <p>{`In order to renovate mature climbers that may have got out of hand, hard prune to 2 ft from the ground in early Spring and thin out vigorous re-growth and tie in the new shoots.`}</p>
    <h4>{`Suggested plants`}</h4>
    <p>{`Lonicera japonica ‘Halliana’ AGM – An evergreen or semi-evergreen, vigorous climber with dark green leaves and white fragrant flowers from Spring to Summer.
Lonicera periclymenum ‘Graham Thomas’ AGM – Deciduous and vigorous honeysuckle that has fantastically fragrant white to yellow flowers in mid and late Summer. Red berries in Autumn add colour.`}</p>
    <h2>{`Grape Vines`}</h2>
    <p>{`Growing grapes is becoming increasingly popular in the UK particularly due to our longer and warmer Summers. The key to success is a combination of selecting the correct site and thorough soil preparation, combined with attention to watering and feeding. Grape vines flower during Summer with fruit ready for picking from early Autumn onwards. They need a sheltered site in full sun, ideally against a south or south west facing wall and a support structure like a pergola. Avoid frost pockets unless temporary protection can be provided.`}</p>
    <InlineImageGallery images={[{
      src: "/img/plants/Boskoop_Glory.jpg",
      alt: "Boskoop Glory"
    }, {
      src: "/img/plants/Phoenix_White_Grape.jpg",
      alt: "Phoenix White Grape"
    }, {
      src: "/img/plants/wire-perg.jpg",
      alt: "Wire training"
    }]} mdxType="InlineImageGallery" />
    <h4>{`Planting`}</h4>
    <p>{`Vines tolerate a range of soil types, providing they are free draining. Sandy gravel over chalk is ideal. Improve drainage on heavy soils by adding well-rotted organic matter and grit. A pH of 6.5 – 6.8 is required so lime acid solis to raise their pH. Double dig soil before planting and incorporate a light dressing of well-rotted manure or compost plus a general purpose fertiliser at 3oz per square yard.`}</p>
    <p>{`Plant vines in the dormant season from late Autumn until early Spring to the same depth that they were in the pot, providing the ground is not waterlogged or frozen. In areas with cold winters, March planting is preferable. The vine stems should be greater than ‘pencil’ thickness, so they are not damaged by frost. Tease out the roots and spread them evenly around the planting hole before backfilling with soil.`}</p>
    <p>{`After planting, mulch with a well - rotted organic matter or chipped bark to protect the lower buds from frost. Remove the mulch from around the stem in Spring, to stop the stem from rotting.
Vines can be grown in containers of a general purpose potting compound. Use a container at least 15 inches in depth and diameter.`}</p>
    <h4>{`Maintenance`}</h4>
    <p>{`In February, apply a general fertilliser at 2oz per square yard plus sulphate of potash at half – ounce per square yard to 12 inches either side of the base of the vine. Feed dessert grapes every two weeks with a high potassium fertiliser (ie: tomato feed) from a month after growth starts in the Spring until the grapes start to ripen.
Although vines are fairly drought tolerant, they can suffer from the fungal disease ‘powdery mildew’ if they become too dry at the roots, so water thoroughly in dry Spring and Summer weather.`}</p>
    <h4>{`Pruning`}</h4>
    <p>{`Vines need regular pruning and maintenance throughout the growing season to keep them manageable and productive. The main pruning time is early Winter (from late November or December) with training and the pinching out of new shoots, as well as the thinning of fruits, being carried out during the Spring and Summer months.
In order to keep the vines under control, the recognised pruning system for vines grown against walls, fences and pergolas is called the ‘rod and spur’ method and for further details on this, please visit `}<a parentName="p" {...{
        "href": "http://www.rhs.org.uk"
      }}>{`www.rhs.org.uk`}</a>{` and search in the advice section for grape vines and pruning methods.`}</p>
    <h4>{`Suggested vines`}</h4>
    <p><strong parentName="p">{`‘Boskoop Glory’ – red`}</strong>{`
One of the finest dessert varieties of vines to grow outdoors in the UK, producing sweet and tasty dessert grapes to eat straight from the vine. Although this is one of the most reliable of outdoor dessert grapes to grow in the UK, it still needs plenty of sun and a sheltered spot to get the best results. A sturdy support such as a pergola is crucial. Pruning is essential unless you want it to do more than just throw out great limbs of beautiful leaves so you must create a framework to cut back to each year and limit the number of bunches so that the plant isn't struggling to ripen too many grapes. All vines are a fine combination of theatrical and productive but this one is particularly so, its finely-shaped leaves turning vivid shades of coral and scarlet before they fall in the Autumn`}</p>
    <p><strong parentName="p">{`‘Phoenix’ – white`}</strong>{`
Bred in Germany, this white grape hybrid has been selected for its disease resistance and excellent quality fruits. Grape ‘Phoenix’ produces good sized grapes in densely packed bunches. When fully ripe, these grapes have a light Muscat aroma and an excellent flavour, making them ideal for eating fresh from the vine or for wine-making. With their large leaves and good autumn colour, these vines also make an attractive garden feature when trained over a pergola.`}</p>
    <h2>{`Evergreen/Scented`}</h2>
    <h4>{`Akebia Quinata - Common name: The Chocolate Vine`}</h4>
    <p>{`A very pretty scented, semi-evergreen climber. Beautiful maroon-chocolate flowers between march and May. Exotic spicy fragrance with a hint of vanilla. Requires moist and well drained soil conditions. Prune back after flowering where necessary.`}</p>
    <h4>{`Clematis Armandii`}</h4>
    <p>{`Fantastic evergreen climber with glossy dark green lance shaped leaves. Beautiful white fragrant flowers in early Spring. Choose a sheltered position and plant either direct in ground or container. Keep roots cool in a moist and well drained soil. Pruning Group 1 (see above for further details)`}</p>
    <h4>{`Jasminum Officinale`}</h4>
    <p>{`Sweetly scented deciduous climber. White flowers from June to August. Appreciates a sheltered position with a fertile and well drained soil. Pruning - after flowering remove old and overcrowded shoots and prune hard in autumn if necessary`}</p>
    <InlineImageGallery images={[{
      src: "/img/plants/Akebia_Quinata_-_The_Chocolate_vine2.jpg",
      alt: "Akebia Quinata - The Chocolate vine"
    }, {
      src: "/img/plants/Clematis_Armandii2.jpg",
      alt: "Clematis Armandii"
    }, {
      src: "/img/plants/Jasmine_Officinale.jpg",
      alt: "Jasmine Officinale"
    }]} mdxType="InlineImageGallery" />
    <h2>{`Training Climbing Plants`}</h2>
    <p>{`Tying in climbers must be done when the stems are soft and pliable. Secure verticle wires or wire mesh to the posts of the pergola and tie in shoots as they grow. Shoots do grow quickly in Spring and Summer and they may have to be tied and secured once a week depending on conditions. Use a garden twine and take care not to bruise or damage the shoots or stems by tying too tightly. To cover the canopy of the pergola as quickly as possible, train the shoots straight up the posts and fix a number of separate wires from one end of the pergola to the other at right angles to the cross rafters (see bottom picture on left hand side of page) and curl shoots around them as the grow. For flowering on the posts, spiral the shoots around the pillars and tie in as normal. Continue to tie in new shoots to the posts and cross rafters until the pergola is well covered.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      