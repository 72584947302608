import React from 'react';

import '@src/scss/styles.scss';

import Layout from '../layouts/Layout';


const Generic = ({ children, pageContext }) => (
    <Layout
        mainClassName="wrapper-top--large wrapper-bottom--medium container container--small wysiwyg"
        metaData={pageContext.frontmatter}
    >
        {children}
    </Layout>
);


export default Generic;
